<template>
  <div>
    <!-- <img src="./bg.png" style="width: 100%;height: 500px;" class="" /> -->
    <div class="videoList my_warp">
      <div v-for="item in videoList" :key="item.id" class="videoList_child">
        <div class="grid-content bg-purple">
          <!-- <video :src="item.url" width="350" height="400" controls="controls">
            <source src="item.url" />
            <object width="350" height="400">
              <embed width="350" height="400" />
            </object>
          </video> -->
          <img :src="item.cover" height="260" style="cursor: pointer;width:100%;" @click="gotoInfo(item)" />
          <div class="mask" v-if="item.payment_mode == 1 && !$store.state.vip">
            <el-button type="text" style="color: #f79400" @click="goMoney">付费播放</el-button>
          </div>
          <div class="title">
            {{ item.desc }}
          </div>
          <div class="user">
            <div class="user">
              <img :src="item.user_avatar" alt="" />
              <span style="margin-left: 8px;">{{ item.user_nickname }}</span>
            </div>
<!--             <div style="padding-right: 4px">
              {{ $moment(item.create_at).format("L") }}
            </div> -->
          </div>
        </div>
      </div>
      <!-- <el-pagination layout="prev, pager, next" :total="1000"> </el-pagination> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      videoList: [],
    };
  },
  mounted() {
    if (this.$store.state.login_token) {
      this.getInfo();
    }
    this.getVideoList();
  },
  methods: {
    async getVideoList() {
      const [err, res] = await this.$http.post("web/index/video", {
        page: 1,
      });
      if (err) {
        return;
      }
      this.videoList = res.list;
    },
    goMoney() {
      this.$router.push("/deposit");
    },
    gotoInfo(date) {
      this.$router.push({ path: "/live", query: { id: date.id } });
    },
    async getInfo() {
      const [err, res] = await this.$http.post("web/User/getInfo", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        this.$router.push("/");
        this.$store.commit("CHANGE_LOGIN_TOKEN", "");
        this.$store.commit("CHANGE_NICK_NAME", "");
        this.$store.commit("CHANGE_USER_ID", "");
        localStorage.setItem("stateInfo", JSON.stringify(this.$store.state));
        return;
      }
      this.$store.commit("CHANGE_NICK_NAME", res.nickname);
      this.$store.commit("CHANGE_USER_ID", res.id);
      this.$store.commit("CHANGE_AVATAR", res.avatar);
      this.$store.commit("CHANGE_VIP", res.vip);
    },
  },
};
</script>

<style lang="less" scoped>
.videoList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // align-content: flex-start;
  color: #fff;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 400px);
}

.videoList:after {
  content: "";
  width: 100%;
}

.videoList_child {
  width: calc(100% / 3 - 50px);
  height: 355px;
  position: relative;

  .mask {
    width: 100%;
    height: 260px;
    background: #000;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0px;
    display: flex;
    justify-content: center;
  }
}

.grid-content {
  width: 100%;

  .title {
    font-size: 16px;
    font-weight: 700;
    padding: 8px 0;
    color: #000;
  }

  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #130f0f;

    img {
      width: 26px;
      height: 26px;
      border-radius: 30px;
    }
  }
}

.el-col-lg-6 {
  width: 20%;
}
</style>